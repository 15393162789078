import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { initSelect2 } from '@/layouts/application/packages/select2';

export default class FilterForm {
  constructor() {
    this.filterForm = '#filter-form';
    this.hiddenRemoveImageSelector = '#hidden-remove-button';
    this.addFilterButton = '.add-filter-button';
    this.removeFilterButton = '.remove-filter-button';
    this.filterItemFormSelector = '.filter-item-form';
    this.filterItemGroupSelector = '.filter-item-group';
    this.filterGroupSelector = '.filter-group';
    this.filterNameSelector = '.filter-name-selector';
    this.filterValuesGroupSelector = '.filter-values-group';
    this.filterConditionsSelector = '.filter-conditions-selector';
    this.filterValuesSelector = '.filter-value-selector';
    this.addFinancialFilterGroup = '#financial-filter';
    this.clientTags = $('#general-filter').data('tags');
    this.clientNames = $('#general-filter').data('contact-names');
    this.clientIds = $('#general-filter').data('contact-ids');
  }

  setup() {
    this.handleAddNewFilter();
    this.handleOnChangeFilterName();
    this.markSetupAsFinish();
    this.buildFilterBasedOnParams();
    handleMoneyField(this.filterForm);
  }

  handleAddNewFilter() {
    $(this.addFilterButton).on('click', (e) => {
      const filterGroup = $(e.target).parents('.filter-group');
      const groupType = filterGroup.data('type')
      let filterItemGroupHtml = undefined;

      switch (groupType) {
        case 'financial':
          filterItemGroupHtml = this.buildNumberFilterItemGroup('financial');
          break;
        case 'ratio':
          filterItemGroupHtml = this.buildNumberFilterItemGroup('ratio');
          break;
        case 'coverage':
          filterItemGroupHtml = this.buildNumberFilterItemGroup('coverage');
          break;
        case 'dream':
          filterItemGroupHtml = this.buildDreamFilterItemGroup();
          break;
        case 'general':
          filterItemGroupHtml = this.buildGeneralFilterItemGroup();
          break;
        default:
          break;
      }

      $(filterGroup).find(`${this.filterItemFormSelector}`).append(filterItemGroupHtml);
      this.addRemoveFilterButton();
      this.updateAvailableFilterNameList(filterGroup);
      this.updateFilterValues();
      this.handleRemoveFilterItem();
      this.triggerSelect2(filterGroup);
      this.toggleAddFilterButton(filterGroup);
    })
  }

  handleRemoveFilterItem() {
    $('#filter-form').on('click', '.remove-filter-button', (e) => {
      const filterGroup = $(e.target).closest(this.filterGroupSelector);

      if (filterGroup.length == 0) {
        return;
      }

      $(e.target).closest(this.filterItemGroupSelector).remove();
      this.updateAvailableFilterNameList(filterGroup);
      this.toggleAddFilterButton(filterGroup);
      this.triggerSelect2(filterGroup);
    })
  }

  handleOnChangeFilterName() {
    $('#filter-form').on('change', '.filter-name-selector select', (e) => {
      const filterGroup = $(e.target).parents(`${this.filterGroupSelector}`);
      const itemGroup= $(e.target).closest(this.filterItemGroupSelector);
      let filterNames = $(filterGroup).data('filter-names');
      const selectedValue = $(e.target).val();

      const newFilterItem = this.buildSelectTagFormGroup(filterNames, `[${selectedValue}][n]`, selectedValue, 'col-5 filter-name-selector');
      $(e.target).parents('.form-group').replaceWith(newFilterItem);

      this.updateAvailableFilterNameList(filterGroup);
      this.updateFilterConditions(filterGroup, itemGroup, selectedValue);
      this.updateFilterValues();
      this.updateFilterValuesFollowFilterName(filterGroup, itemGroup, selectedValue);
      this.updateDependantNames(itemGroup, selectedValue);
      this.triggerSelect2(filterGroup);
    })
  }

  markSetupAsFinish() {
    $('#filter-modal').attr('data-setup-finished', true)
  }

  updateFilterItem(filterNames, filterName, selectedValue) {
    const fullFilterName = `filter[${filterName}][n]`;
    const selectFilter = this.findSelectByName(fullFilterName)
    const newFilterItem = this.buildSelectTagFormGroup(filterNames, `[${filterName}][n]`, selectedValue, 'filter-name-selector');

    $(selectFilter).parents('.form-group').replaceWith(newFilterItem);
  }

  buildHealthValueGroup(selectedvalue=undefined) {
    return  `
              <span class='filter-conditions-selector my-auto px-0'></span>
              <div class='filter-values-group col-5'>
                ${this.buildSelectTagFormGroup(["Planned for", "Not Planned for"], '[t][fV]', selectedvalue || 'Planned for', 'filter-value-selector')}
              </div>
            `
  }

  buildNumberFilterItemGroup(filterType, selectedData={}) {
    const $filterGroupSelector = $(`#${filterType}-filter`);
    let filterNames = $filterGroupSelector.data('filter-names');
    let filterConditions = $filterGroupSelector.data('filter-conditions');

    if(filterNames.length == 0) {
      return;
    }

    const shorterName = selectedData['n'] || this.mapValuesToShortParams(filterNames[0]);
    const shorterCon = selectedData['con'] || this.mapValuesToShortParams(filterConditions[0]);
    const numberDataGroup = `
                              ${this.buildSelectTagFormGroup(filterConditions, `[${shorterName}][con]`, shorterCon, 'col-5 filter-conditions-selector')}
                              ${this.buildFilterNumberValues(shorterCon, shorterName, selectedData['fV'], selectedData['sV'])}
                            `
    return  `
              <div class='filter-item-group my-3 d-flex'>
                <div class='d-none remove-filter-button-temp'></div>
                <div class='row w-100'>
                  ${this.buildSelectTagFormGroup(filterNames, `[${shorterName}][n]`, shorterName, 'col-5 filter-name-selector')}
                  <span class='my-auto px-0'>is</span>
                  ${ shorterName == 't' ? this.buildHealthValueGroup(selectedData['fV']) : numberDataGroup }
                </div>
              </div>
            `
  }

  buildSelectTagFormGroup(options, selectName, selectedValue, className, displayOptions = undefined) {
    return `
             <div class='form-group ${className}'>
               ${this.buildSelectTag(options, selectName, selectedValue, displayOptions)}
             </div>
           `
  }

  buildSelectTag(options, selectName, selectedValue, displayOptions = undefined) {
    if(displayOptions == undefined) {
      displayOptions = options
    }

    return `
            <select class='form-control optional select2' name='filter${selectName}'  >
              ${
                `${options.map((optionValue, index) => {
                  const shortValue = this.mapValuesToShortParams(optionValue)
                  return `
                          <option value='${shortValue}' ${shortValue == selectedValue ? "selected='selected'" : ''}">${displayOptions[index]}</option>
                        `
                })}`.replaceAll(',', '')
              }
            </select>
          `
  }

  buildDreamFilterItemGroup(selectedData={}) {
    const $filterGroupSelector = $(`#dream-filter`);
    let filterNames = $filterGroupSelector.data('filter-names');
    let filterValues = $filterGroupSelector.data('filter-values');

    if(filterNames.length == 0) {
      return;
    }

    const filterName = selectedData['n'] || this.mapValuesToShortParams(filterNames[0]);
    const selectedValue = selectedData['fV'] || filterValues[0];
    if(filterName === 'u') {
      filterValues =  ['Achievable', 'Unachievable'];
    }
    return `<div class='filter-item-group my-3 d-flex'>
              <div class='d-none remove-filter-button-temp'></div>
              <div class='row w-100'>
                ${this.buildSelectTagFormGroup(filterNames, `[${filterName}][n]`, filterName, 'col-5 filter-name-selector')}
                <span class='filter-conditions-selector my-auto px-0'>is</span>
                <div class='filter-values-group col-5'>
                  ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, selectedValue, 'filter-value-selector')}
                </div>
              </div>
            </div>`
  }

  buildGeneralFilterItemGroup(selectedData={}) {
    const $filterGroupSelector = $(`#general-filter`);
    let filterNames = $filterGroupSelector.data('filter-names');

    if(filterNames.length == 0) {
      return;
    }

    const filterName = selectedData['n'] || this.mapValuesToShortParams(filterNames[0]);
    const condition = selectedData['con'] || 'eq';
    const firstValue = selectedData['fV'];
    const secondValue = selectedData['sV'];
    let filterConditionGroup = `<span class='filter-conditions-selector my-auto px-auto'>is</span>`
    let filterValuesGroup = `
                              <div class='filter-values-group d-flex align-items-center col-5'>
                                ${this.buildGeneralFilterValues(condition, filterName, firstValue, secondValue)}
                              </div>
                            `
    if(filterName == 'ha') {
      filterConditionGroup = `
                                <span class='my-auto px-0'>is</span>
                                ${this.buildSelectTagFormGroup(["equal to", "less than", "more than", "between"], `[${filterName}][con]`, condition, 'col-5 filter-conditions-selector')}
                             `;
      filterValuesGroup = `${this.buildGeneralFilterValues(condition, filterName, firstValue, secondValue)}`;
    }

    return `<div class='filter-item-group my-3 d-flex align-item-center'>
              <div class='d-none remove-filter-button-temp'></div>
              <div class='row w-100'>
                ${this.buildSelectTagFormGroup(filterNames, `[${filterName}][n]`, filterName, 'col-5 filter-name-selector')}
                ${filterConditionGroup}
                ${filterValuesGroup}
              </div>
            </div>
           `
  }

  findSelectByName(name) {
    const selectTags = $('select');
    for(let i=0; i< selectTags.length; i++) {
      if($(selectTags[i]).attr('name') === name) {
        return selectTags[i];
      }
    }
  }

  updateAvailableFilterNameList(groupSelector) {
    const dataListOrignal = $(groupSelector).data('filter-names-original');
    let dataList = dataListOrignal.slice(0);
    const filterNameList = $(groupSelector).find('.filter-name-selector select');
    for(let i=0; i<filterNameList.length; i++) {
      const seletedValue = $(filterNameList[i]).find(':selected').text();
      const pos = dataList.indexOf(seletedValue);
      dataList.splice(pos, 1);
    }

    $(groupSelector).data('filter-names', dataList);
    this.updateAvailableOptionsList($(groupSelector).closest(this.filterGroupSelector), groupSelector, dataList)
  }

  updateAvailableOptionsList(filterGroup, currentGroup, availableOptions) {
    $(filterGroup).find(`${this.filterNameSelector} select`).each((_index, element) => {
      if($(element).closest('.filter-item-form')[0] != currentGroup[0]) {
        const selectName = $(element).attr('name').replace('filter', '');
        const selectedValue = $(element).val();
        let newOptions = availableOptions.slice(0);
        newOptions.push($(element).find(':selected').text());
        const newOptionList = this.buildSelectTag(newOptions, selectName, selectedValue);
        $(element).data('select2') && $(element).select2('destroy');
        $(element).replaceWith(newOptionList);
      }
    });
  }

  buildGeneralFilterValues(conditionValue, filterName, firstV=undefined, secondV=undefined) {
    let firstValueSelected;
    let valueString;

    switch (filterName) {
      case 'fa':
        firstValueSelected = firstV || this.clientIds[0];
        return `${this.buildSelectTagFormGroup(this.clientIds, `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100', this.clientNames)}`;
      case 'ga':
        firstValueSelected = firstV || 'Leads';
        return `${this.buildSelectTagFormGroup(["Leads", "Approached", "Met", "Closed"], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
      case 'ha':
        return this.buildFilterNumberValues(conditionValue, 'ha', firstV, secondV);
      case 'ia':
        firstValueSelected = firstV || 'Referral';
        return `${this.buildSelectTagFormGroup(["Referral", "Warm", "Telemarketing", "Roadshows", "Seminars", "Stray", "Others"], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
      case 'ja':
        firstValueSelected = firstV || this.clientTags[0];
        return `${this.buildSelectTagFormGroup(this.clientTags, `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100', this.clientTags)}`;
      case 'ka':
        firstValueSelected = firstV || 'Yes';
        return `${this.buildSelectTagFormGroup(['Yes', 'No'], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
      case 'la':
        firstValueSelected = firstV || 'Full time';
        return `${this.buildSelectTagFormGroup(["Full time", "Househusband", "Housewife", "Part time", "Retired", "Self employed", "Student", "Unemployed", "Others"], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
      case 'ma':
        firstValueSelected = firstV || 'Male';
        return `${this.buildSelectTagFormGroup(["Male", "Female"], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
      case 'na':
        firstValueSelected = firstV || 'Married';
        return `${this.buildSelectTagFormGroup(["Married", "Single", "Divorced", "Widowed"], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
      case 'oa':
        valueString = firstV == undefined ? '' : `value='${firstV}'`;
        return `
                <div class='form-group string'>
                  <input class='form-control string filter-value-selector' name=[${filterName}][fV] ${valueString}/>
                </div>
               `
      case 'pa':
        firstValueSelected = firstV || '0';
        return `${this.buildSelectTagFormGroup(["0", "1", "2", "3", "4"], `[${filterName}][fV]`, firstValueSelected, 'w-100 filter-value-selector w-100')}`
      case 'qa':
        firstValueSelected = firstV || 'Pre-Appointment';
        return `${this.buildSelectTagFormGroup(["Pre-Appointment", "Post-Appointment", "Pay It Forward", "Money Personality Quiz", "Career Profiling Quiz"], `[${filterName}][fV]`, firstValueSelected, 'filter-value-selector w-100')}`
    }
  }

  updateFilterConditions(filterGroup, itemGroup, filterName) {
    let newConditionsGroup = undefined;
    if($(filterGroup).data('type') === 'coverage') {
      newConditionsGroup = this.buildCoverageFilterConditions(filterName);
    } else if($(filterGroup).data('type') == 'general') {
      this.cleanupNonFilterConditions(itemGroup, filterName);
      newConditionsGroup = this.buildGeneralFilterConditions(filterName);
    }

    if(newConditionsGroup == undefined) {
      return;
    }

    const conditionsGroup = $(itemGroup).find(this.filterConditionsSelector);
    $(conditionsGroup).replaceWith(newConditionsGroup)
  }

  cleanupNonFilterConditions(itemGroup, filterName) {
    if (filterName !== 'ha') {
      const selector = 'span.my-auto.px-0:not(.filter-conditions-selector)';
      $(itemGroup).find(selector).remove();
    }
  }

  buildCoverageFilterConditions(filterName) {
    if(filterName === 't') {
      return `
              <span class='filter-conditions-selector my-auto px-0'></span>
            `
    } else {
      return this.numberFilterConditionsGroup(filterName);
    }
  }

  buildGeneralFilterConditions(filterName) {
    if(filterName == 'ha') {
      return `
               <span class='my-auto px-0'>is</span>
               ${this.buildSelectTagFormGroup(['equal to', 'less than', 'more than', 'between'], `[${filterName}][con]`, 'eq', 'col-5 filter-conditions-selector')}
             `
    }

    return `<span class='filter-conditions-selector my-auto px-0'>is</span>`
  }

  numberFilterConditionsGroup(filterName) {
    const filterConditions = ['equal to', 'less than', 'more than', 'between']
    const shorterCon = this.mapValuesToShortParams(filterConditions[0])

    return `
            ${this.buildSelectTagFormGroup(filterConditions, `[${filterName}][con]`, shorterCon, 'col-5 filter-conditions-selector')}
          `
  }

  updateFilterValues() {
    $('#filter-form').on('change', '.filter-conditions-selector select', (e) => {
      const conditionValue = $(e.target).val();
      const filterName = $(e.target).attr('name').replace('filter[', '').replace('][con]', '');
      const itemGroup = $(e.target).closest(this.filterItemGroupSelector);
      const filterValuesGroup = $(itemGroup).find(this.filterValuesGroupSelector)
      const newValuesGroup = this.buildFilterNumberValues(conditionValue, filterName);

      if(newValuesGroup != undefined) {
        $(filterValuesGroup).replaceWith(newValuesGroup);
      }
    })
  }

  buildFilterNumberValues(conditionValue, filterName, firstV=undefined, secondV=undefined) {
    const firstValueString = firstV != undefined ? `value='${firstV}'` : ''
    const secondValueString = secondV != undefined ? `value='${secondV}'` : '';

    switch (conditionValue) {
      case 'ib':
        return `
                <div class='filter-values-group d-flex'>
                  <div class='col-5 form-group filter-value-selector'>
                    <input class='form-control optional money allow-negative'  name='filter[${filterName}][fV]' ${firstValueString}/>
                  </div>

                  <span class='col-1 my-auto px-0'>and</span>

                  <div class='col-5 form-group filter-value-selector'>
                    <input class='form-control optional money allow-negative'  name='filter[${filterName}][sV]' ${secondValueString}/>
                  </div>
                </div>
               `
      case 'eq':
      case 'ilt':
      case 'imt':
        return `
                <div class='filter-values-group col-5'>
                  <div class='form-group filter-value-selector'>
                    <input class='form-control optional money allow-negative'  name='filter[${filterName}][fV]' ${firstValueString}/>
                  </div>
                </div>
               `
      default:
        break;
    }
  }

  updateFilterValuesFollowFilterName(filterGroup, filterItemGroup, filterName) {
    switch ($(filterGroup).data('type')) {
      case 'coverage':
        this.updateFilterValuesForCoverage(filterItemGroup, filterName);
        break;
      case 'dream':
        this.updateFilterValuesForDream(filterItemGroup, filterName);
        break;
      case 'general':
        this.updateFilterValueForGeneral(filterItemGroup, filterName);
        break;
      default:
        break;
    }
  }

  updateFilterValuesForCoverage(filterItemGroup, filterName) {
    let newFilterValuesGroup = undefined;
    if(filterName == 't') {
      newFilterValuesGroup = ` <div class='filter-values-group col-5'>
                                ${this.buildSelectTagFormGroup(["Planned for", "Not Planned for"], `[${filterName}][fV]`, 'Planned for', 'filter-value-selector')}
                              </div>
                            `
    } else {
      newFilterValuesGroup = this.buildFilterNumberValues('eq', filterName);
    }
    const filterValuesGroup = $(filterItemGroup).find(this.filterValuesGroupSelector);
    $(filterValuesGroup).replaceWith(newFilterValuesGroup);
  }

  updateFilterValuesForDream(filterItemGroup, filterName) {
    let newFilterValuesGroup = undefined;

    if(filterName == 'u') {
      newFilterValuesGroup = ` <div class='filter-values-group col-5'>
                                ${this.buildSelectTagFormGroup(["Achievable", "Unachievable"], `[${filterName}][fV]`, 'Achievable', 'filter-value-selector')}
                               </div>
                             `
    } else {
      newFilterValuesGroup = ` <div class='filter-values-group col-5'>
                                ${this.buildSelectTagFormGroup(["Planned for", "Achievable", "Unachievable", "Not Planned for"], `[${filterName}][fV]`, 'Planned for', 'filter-value-selector')}
                               </div>
                             `
    }
    const filterValuesGroup = $(filterItemGroup).find(this.filterValuesGroupSelector);
    $(filterValuesGroup).replaceWith(newFilterValuesGroup);

  }

  updateFilterValueForGeneral(filterItemGroup, filterName) {
    let newFilterValuesGroup = undefined;
    let filterValues = undefined;
    switch (filterName) {
      case 'fa':
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(this.clientIds, `[${filterName}][fV]`, this.clientIds[0], `filter-value-selector`, this.clientNames)}
                                </div>
                                `
        break;
      case 'ga':
        filterValues = ["Leads", "Approached", "Met", "Closed"]
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                 ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, filterValues[0], `filter-value-selector`)}
                                </div>
                               `
        break;
      case 'ha':
        newFilterValuesGroup = this.buildFilterNumberValues('eq', filterName);
        break;
      case 'ia':
        filterValues = ["Referral", "Warm", "Telemarketing", "Roadshows", "Seminars", "Stray", "Others"];
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                 ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, filterValues[0], `filter-value-selector`)}
                                </div>
                               `
        break;
      case 'ja':
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(this.clientTags, `[${filterName}][fV]`, this.clientTags[0], `filter-value-selector`, this.clientTags)}
                                </div>
                               `
        break;
      case 'ka':
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(['Yes', 'No'], `[${filterName}][fV]`, 'Yes', 'filter-value-selector')}
                                </div>
                               `
        break;
      case 'la':
        filterValues = ["Full time", "Househusband", "Housewife", "Part time", "Retired", "Self employed", "Student", "Unemployed", "Others"];
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, filterValues[0], 'filter-value-selector')}
                                </div>
                               `
        break;
      case 'ma':
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(['Male', 'Female'], `[${filterName}][fV]`, 'Male', 'filter-value-selector')}
                                </div>
                               `
        break;
      case 'na':
        filterValues = ["Married", "Single", "Divorced", "Widowed"];
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, filterValues[0], 'filter-value-selector')}
                                </div>
                               `
        break;
      case 'oa':
        newFilterValuesGroup = `<div class='form-group filter-values-group col-5'>
                                  <input class='form-control filter-value-selector' name=[${filterName}][fV]/>
                                </div>
                              `
        break;
      case 'pa':
        filterValues = ["0", "1", "2", "3", '4'];
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, filterValues[0], 'filter-value-selector')}
                                </div>
                               `
        break;
      case 'qa':
        filterValues = ["Pre-Appointment", "Post-Appointment", "Pay It Forward", "Money Personality Quiz", "Career Profiling Quiz"];
        newFilterValuesGroup = `<div class='filter-values-group col-5'>
                                  ${this.buildSelectTagFormGroup(filterValues, `[${filterName}][fV]`, filterValues[0], 'filter-value-selector')}
                                </div>
                               `
        break;
    }
    const filterValuesGroup = $(filterItemGroup).find(this.filterValuesGroupSelector);
    $(filterValuesGroup).replaceWith(newFilterValuesGroup);
  }

  updateDependantNames(itemGroup, filterName) {
    const conditionElement = this.findDependantConditionElement(itemGroup);
    const newConditionName = `filter[${filterName}][con]`;
    $(conditionElement).attr('name', newConditionName);

    $(itemGroup).find('.filter-value-selector').each((index, element) => {
      const valueElement = this.findDependantValueElement(element);
      const newValueName = index == 0 ? `filter[${filterName}][fV]` : `filter[${filterName}][sV]`
      $(valueElement).attr('name', newValueName);
    })
  }

  findDependantConditionElement(itemGroup) {
    const conditionElement = $(itemGroup).find('.filter-conditions-selector');
    const selectTargetElement = $(conditionElement).find('select');
    const spanTargetElement = $(conditionElement).find('span');
    if(selectTargetElement.length != 0) {
      return selectTargetElement;
    } else if(spanTargetElement.length != 0) {
      return spanTargetElement;
    } else {
      return conditionElement;
    }
  }

  findDependantValueElement(element) {
    const selectTargetElement = $(element).find('select');
    const inputTargetElement = $(element).find('input');
    if(selectTargetElement.length != 0) {
      return selectTargetElement;
    } else if(inputTargetElement.length != 0) {
      return inputTargetElement;
    } else {
      return element;
    }
  }

  mapValuesToShortParams(value) {
    switch (value) {
      case 'Total Net Income':
        return 'a'
      case 'Total Expenses':
        return 'b'
      case 'Cash Surplus':
        return 'c'
      case 'Total Assets':
        return 'd'
      case 'Total Liabilities':
        return 'e'
      case 'Net Worth':
        return 'f'
      case 'Total Investment Assets':
        return 'g'
      case 'Total Premiums (Include all policy premiums)':
        return 'h'
      case 'Basic Liquidity Ratio':
        return 'i'
      case 'Net Investment Asset To Net Worth Ratio':
        return 'j'
      case 'Debt To Asset Ratio':
        return 'k'
      case 'Savings Ratio':
        return 'l'
      case 'Expense Ratio':
        return 'm'
      case 'Death':
        return 'n'
      case 'TPD':
        return 'o'
      case 'Critical Illness':
        return 'p'
      case 'Early Critical Illness':
        return 'q'
      case 'Disability Income':
        return 'r'
      case 'Personal Accident':
        return 's'
      case 'Health':
        return 't'
      case 'Retirement':
        return 'u'
      case 'Vehicle':
        return 'v'
      case 'House':
        return 'w'
      case 'Travel':
        return 'y'
      case 'Education':
        return 'z'
      case 'Start A Business':
        return 'aa'
      case 'Having a Child':
        return 'ba'
      case 'Wedding':
        return 'ca'
      case 'Custom':
        return 'da'
      case 'Gifting':
        return 'ea'
      case 'Referrer':
        return 'fa'
      case 'Contact Type':
        return 'ga'
      case 'Age':
        return 'ha'
      case 'Source':
        return 'ia'
      case 'Tags':
        return 'ja'
      case 'Smoking Habit':
        return 'ka'
      case 'Employment Status':
        return 'la'
      case 'Gender':
        return 'ma'
      case 'Marital Status':
        return 'na'
      case 'Birth Year':
        return 'oa'
      case 'Rating':
        return 'pa'
      case 'Forms':
        return 'qa'
      case 'equal to':
        return 'eq'
      case 'less than':
        return 'ilt'
      case 'more than':
        return 'imt'
      case 'between':
        return 'ib';
      default:
        return value;
    }
  }

  toggleAddFilterButton(filterGroup) {
    const addFilterButton = $(filterGroup).find('.add-filter-button');
    if($(filterGroup).data('filter-names').length > 0) {
      $(addFilterButton).show();
    } else {
      $(addFilterButton).hide();
    }
  }

  buildFilterBasedOnParams() {
    const paramsData = this.getFiterData();
    const filterType = paramsData[0];
    const filterData = paramsData[1];

    Object.keys(filterData).forEach((filterName) => {
      let filterGroup = undefined;
      let newFilterItem = '';
      const selectedData = filterData[filterName];
      switch (true) {
        case this.isFinancialShortName(filterName):
          filterGroup = $('#financial-filter');
          newFilterItem = this.buildNumberFilterItemGroup('financial', selectedData)
          break;
        case this.isRatioShortName(filterName):
          filterGroup = $('#ratio-filter');
          newFilterItem = this.buildNumberFilterItemGroup('ratio', selectedData)
          break;
        case this.isCoverageShortName(filterName):
          filterGroup = $('#coverage-filter');
          newFilterItem = this.buildNumberFilterItemGroup('coverage', selectedData)
          break;
        case this.isDreamShortName(filterName):
          filterGroup = $('#dream-filter');
          newFilterItem = this.buildDreamFilterItemGroup(selectedData);
          break;
        case this.isGeneralShortName(filterName):
          filterGroup = $('#general-filter');
          newFilterItem = this.buildGeneralFilterItemGroup(selectedData);
          break;
        default:
          return;
      }

      $(filterGroup).find(this.filterItemFormSelector).append(newFilterItem);
      this.addRemoveFilterButton();
      this.handleRemoveFilterItem();
      this.updateAvailableFilterNameList(filterGroup);
      this.toggleAddFilterButton(filterGroup);
      this.triggerSelect2(filterGroup);
      this.updateFilterValues();
    });

    $('input.money').trigger('input');
  }

  getFiterData() {
    let filterData = {}
    let params = window.location.search.replaceAll('filter','').replaceAll('%5B', '[').replaceAll('%5D', ']').split('&');
    const filterType = params[0];
    params = params.slice(1, params.length - 1);

    params.forEach(element => {
      let filter, value;
      filter = element.split('=')[0];
      filter = filter.replace('][', '_').replaceAll('[', '').replaceAll(']', '').split('_')
      const filterName = filter[0];
      const filterCon = filter[1];
      value = element.split('=')[1].replaceAll('+', ' ');

      if(filterData[filterName]) {
        filterData[filterName][filterCon] = value;
      } else {
        let newCondition = {}
        newCondition[filterCon] = value
        filterData[filterName] = newCondition
      }
    });

    return [filterType, filterData];
  }

  isFinancialShortName(name) {
    const financialNames = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

    return financialNames.includes(name);
  }

  isRatioShortName(name) {
    const ratioNames = ['i', 'j', 'k', 'l', 'm'];

    return ratioNames.includes(name);
  }

  isCoverageShortName(name) {
    const coverageNames = ['n', 'o', 'p', 'q', 'r', 's', 't'];

    return coverageNames.includes(name);
  }

  isDreamShortName(name) {
    const dreamNames = ['u', 'v', 'w', 'y', 'z', 'aa', 'ba', 'ca', 'da', 'ea'];

    return dreamNames.includes(name);
  }

  isGeneralShortName(name) {
    const generalNames = ['fa', 'ga', 'ha', 'ia', 'ja', 'ka', 'la', 'ma', 'na', 'oa', 'pa', 'qa'];

    return generalNames.includes(name);
  }

  triggerSelect2(filterGroup) {
    initSelect2();
    $(filterGroup).find('select').select2({minimumResultsForSearch: 10});
  }

  addRemoveFilterButton() {
    $(this.filterForm).find('.remove-filter-button-temp').replaceWith($(this.hiddenRemoveImageSelector).find('img').clone());
  }
}
